module.exports = require('deepmerge')(require('./config.default.js'), {
  native: {
    name: {
      default: 'MOM_D'
    },
    id: 'com.mobilespot.moo.dev',
    // iOS
    appleTeamId: '2BAP3P29V2',
    // iosAppStoreUrl: 'https://itunes.apple.com/app/id1484463502',
    // Android
    // 'androidapp-id': 'TODO', // unused?
    playStoreUrl: ''
  },
  // which additional repos need to be cloned
  relatedRepos: [{
    src: 'git@bitbucket.org:mobilespotdev/cordova.git',
    branch: 'inte/mom0123/main',
    postCommands: ['cd cordova && npm install']
  }, {
    src: 'git@bitbucket.org:mobilespotdev/mobigeo.git',
    branch: 'inte/mom0123/main',
    postCommands: ['cd mobigeo && npm install']
  }, {
    src: 'git@bitbucket.org:mobilespotdev/mobigeo-customs-master.git',
    branch: 'inte/mom0123/main',
    dest: 'mobigeo/mobigeo-customs'
  }, {
    src: 'git@bitbucket.org:mobilespotdev/app-react.git',
    branch: 'inte/mom0123/mainv'
  }, {
    src: 'git@bitbucket.org:mobilespotdev/data-master.git',
    branch: 'inte/mom0123/main',
    dest: 'app-react/app-customs',
    postCommands: ['cd app-react && yarn', 'cd app-react && npm run set-env dev', 'cd app-react && npm run download-assets', 'cd app-react && npm run download-data-files', 'cd app-react && npm run update-mobigeo']
  }],
  web: {
    url: 'mom0123.ms-prod.mobile-spot.com '
  }
});