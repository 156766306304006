module.exports = {
  "name": "mom0123",
  "client": "Mobile spot",
  "description": "Mobile app for test",
  "validLangs": ["fr", "en"],
  "version": {
    "mainVersion": "10.0.0",
    "buildVersion": {
      "iOS": "16",
      "android": "15",
      "web": "110"
    }
  },
  "native": {
    "author": "Mobile-Spot",
    "backgroundColor": "0xffffff",
    "iosBuildTarget": "11.0",
    "androidFadeSplashScreenDuration": 750,
    "androidTargetSdkVersion": 31,
    "androidSigning": {
      "keyAlias": "mobilespotmom",
      "keyStorePassword": "jee1Uu0Hieloh7ba"
    },
    "app": "app-react"
  },
  "undeliveredFolders": ["source", "exports"],
  "crypto": false,
  "web": {}
};