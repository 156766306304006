import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import config from 'app-customs/config/config';
import projectConfiguration from '../../../../legacy-app-controller/config.js';
import { formatDateTime } from 'src/core/Lang';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import {
  hasWritePermission,
  requestWritePermission,
  onCreateCalendarEventWithOptions,
} from 'src/core/calendar/calendar';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import './ExportEventButton.scss';

const LOG_PREF = '[ExportEvent] ';

class ExportEventButton extends PureComponent {
  constructor(props) {
    super(props);

    this.onExportEventButtonClick = this.onExportEventButtonClick.bind(this);
  }

  /**
   * request permission to create event in the calendar of the user
   */
  requestWritePermissionEvent(e) {
    var _this = this;
    requestWritePermission(
      function requestSuccess() {
        console.info(`${LOG_PREF} request Write PermissionEvent Success`);
        _this.onExportEventButtonClick(e);
      },
      function requestFail(error) {
        console.error(`${LOG_PREF} request Write PermissionEvent Fail`, error);
      }
    );
  }

  /**
   * create an event
   */
  createEvent() {
    const { id, title, start_date, end_time, start_time, location, queryString, actions, labels } =
      this.props;
    var _this = this;
    onCreateCalendarEventWithOptions(
      id,
      title,
      location,
      '',
      formatDateTime(start_date, start_time),
      formatDateTime(start_date, end_time),
      { url: queryString ? 'https:/' + projectConfiguration.web.url + queryString : '' },
      actions,
      labels,
      NotificationLevels
    );
  }

  /**
   * When main button is clicked, create an event in the default calendar choosing by the user
   * @param {object} e
   */
  onExportEventButtonClick(e) {
    e.preventDefault();
    var _this = this;
    hasWritePermission(
      function hasRequestSuccess(permission) {
        console.info(`${LOG_PREF} hasRequestSuccess`, permission);
        if (permission) _this.createEvent();
        else _this.requestWritePermissionEvent(e);
      },
      function hasRequestFail(error) {
        console.error(`${LOG_PREF} hasRequestFail`, error);
      }
    );
  }

  render() {
    if (config.EXPORT_EVENT.FEATURE_ENABLED !== true) {
      return null;
    }

    return (
      <GenericIcon
        layout="div"
        className="export-event-btn calendar-icon"
        title={this.props.labels.common.exportEvent}
        onClick={this.onExportEventButtonClick}
      />
    );
  }
}

ExportEventButton.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  queryString: PropTypes.string.isRequired,
  start_time: PropTypes.string,
  start_date: PropTypes.number.isRequired,
  end_time: PropTypes.string,
  location: PropTypes.string,

  // common props
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ExportEventButton;
