import { VISITOR_PROFILE,
/*EXHIBITOR_PROFILE,*/
WEBAPP_EXH_PROFILE, DEFAULT_PROFILE, //WEBAPP_SPK_PROFILE,
WEBAPP_EVT_PROFILE } from 'app-customs/config/profiles';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { navigateAfterClick } from 'src/pages/choose-profile/chooseProfileUtil';
/**
 * Choose profile page buttons configuration
 *
 * @param {object} actions - store connected actions
 */

export var get = function get() {
  function onClick(selectedProfile, actions, thenNavigateTo) {
    navigateAfterClick(selectedProfile, actions, thenNavigateTo);
  }

  return [{
    value: DEFAULT_PROFILE,
    // reference for the rest of the app
    label: "profile.".concat(DEFAULT_PROFILE),
    props: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Exhibitors.png'), ")"),
        backgroundPosition: '0% 90%',
        backgroundColor: '#a0aac0'
      }
    },
    onClick: onClick
  },
  /* {
    value: VISITOR_PROFILE, // reference for the rest of the app
    label: `profile.${VISITOR_PROFILE}`,
    props: {
      style: {
        backgroundImage: `url(${getUrl('files/project/home/Visitors.png')})`,
        backgroundPosition: '0% 90%',
        backgroundColor: '#a0aac0',
      },
    },
    onClick,
  },
  {
    value: EXHIBITOR_PROFILE, // reference for the rest of the app
    label: `profile.${EXHIBITOR_PROFILE}`,
    props: {
      style: {
        backgroundImage: `url(${getUrl('files/project/home/Exhibitors.png')})`,
        backgroundPosition: '0% 90%',
        backgroundColor: '#a0aac0',
      },
    },
    onClick,
  },*/
  {
    value: WEBAPP_EXH_PROFILE,
    // reference for the rest of the app
    label: "profile.".concat(WEBAPP_EXH_PROFILE),
    props: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Exhibitors.png'), ")"),
        backgroundPosition: '0% 90%',
        backgroundColor: '#a0aac0'
      }
    },
    onClick: onClick
  }, {
    value: WEBAPP_EVT_PROFILE,
    // reference for the rest of the app
    label: "profile.".concat(WEBAPP_EVT_PROFILE),
    props: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Exhibitors.png'), ")"),
        backgroundPosition: '0% 90%',
        backgroundColor: '#a0aac0'
      }
    },
    onClick: onClick
  }
  /*  {
    value: WEBAPP_SPK_PROFILE, // reference for the rest of the app
    label: `profile.${WEBAPP_SPK_PROFILE}`,
    props: {
      style: {
        backgroundImage: `url(${getUrl('files/project/home/Exhibitors.png')})`,
        backgroundPosition: '0% 90%',
        backgroundColor: '#a0aac0',
      },
    },
    onClick,
  },*/
  ];
};
/**
 *  - Ads are displayed on ChooseProfilePage
 *  - Ad config depends on current user profile
 *  => Conclusion: on first display, it is not possible to display ad(s)
 *
 *  When the user profile is not known yet, the page will use this profile to display the ad(s)
 */

export var DEFAULT_AD_PROFILE = DEFAULT_PROFILE;