// Conf
import config from 'app-customs/config/config';
import {
  VALID_DATA_TYPES,
  CATEGORIES_MAPPING,
  CATEGORIES_DATA_TYPE,
  DATA_TYPE_EVENTS,
  DATA_TYPE_FAVORITE_POSITIONS,
} from 'app-customs/config/dataConfig';

import {
  getSortConfig,
  getDataListDialogSortConfig,
  getGroupedItemsSortConfig,
} from 'app-customs/config/sortConfig';

// App modules
import fetchHelper from 'src/core/util/FetchHelper';
import { getCurrent as getCurrentLang } from 'src/core/Lang';
import { getBindedActions } from 'src/store/bindedActions';
import { objectToArray } from 'src/core/util/JsTools';
import { sortStrings, simpleSort } from 'src/core/util/sortUtil';
import { get3CharsLang } from './utils';
import { getAssets } from './Updater';
import * as DataAssetsUtil from './DataAssetsUtil';
import * as DbPersistence from './DbPersistence';
import { init as initFieldsIndex, get as getFieldsIndex } from './DataFieldsIndex';
import { get as getLabels } from 'src/core/Lang';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';

const LOG_PREF = '[Db] ';

/**
 * Hold db_[lang].json content
 * @type {Object}
 */
let data = {};
export const getData = () => data;
export const isDataReady = () =>
  sortedAndTransformedData && Object.keys(sortedAndTransformedData).length > 0;

/**
 * Contains data ready to be used in the app (transformed as objects and sorted)
 * @type {Object}
 */
let sortedAndTransformedData = {};
export const getSortedAndTransformedData = () => sortedAndTransformedData;
if (config.ENV === 'dev') {
  global.getSortedAndTransformedData = getSortedAndTransformedData;
}

export function clearData(dataType) {
  if (sortedAndTransformedData[dataType]) {
    delete sortedAndTransformedData[dataType];
  }
}

const isDataValid = (data) => data !== null && typeof data === 'object';

/**
 * @param  {function} initializeCb     callback
 * @param  {boolean}  fetchFromBackend (optional) allow to force data to be fetched from backend,
 *                                     e.g: local data is missing or invalid
 */
export const initialize = (initializeCb, fetchFromBackend) => {
  // Inform the app that data is being processed
  getBindedActions().dataAssetsUpdating();

  const threeCharsLang = get3CharsLang(getCurrentLang());

  const getNewVersionOfOfflineData = () => {
    console.log(`${LOG_PREF}Getting new version of offline/datas_${threeCharsLang}`);

    const url = DataAssetsUtil.getUrl(
      `offline/db_${threeCharsLang}.json?${new Date().getTime()}`,
      fetchFromBackend
    );

    fetchHelper(
      url,
      null, // options
      true, // is json
      // on success:
      (json) => {
        function setDataWithoutPersistence() {
          data = json;
          prepareData();
          initializeCb();
        }
        if (!global.isCordovaContext) {
          // For web
          setDataWithoutPersistence();
        } else {
          // For cordova
          DbPersistence.set(json, function(err) {
            if (err) {
              setDataWithoutPersistence(); // fallback
              return; // error already logger by DbPersistence
            }

            // reset updater version when restarting from scratch
            if (getAssets() != null) {
              getAssets().listVersion[threeCharsLang] = json.version;
            }
            data.version = json.version;

            readDataFromStorage(initializeCb);
          });
        }
      },
      () => {
        prepareData();
        initializeCb();
        /* getBindedActions().showNotification({
          message: getLabels().common.fetchError,
          level: NotificationLevels.ERROR,
        }); */
      }, // on failure
      false // no error message
    );
  };

  // No data persistence in web environment
  if (global.isCordovaContext !== true) {
    getNewVersionOfOfflineData();
  } else {
    DbPersistence.get(function(err, data) {
      if (err) {
        console.error(`${LOG_PREF}Unable to check presence of stored data`);
        // return;
      }

      if (isDataValid(data)) {
        readDataFromStorage(() => {
          if (data.version >= process.env.EMBARKED_DATA_VERSION) {
            if (typeof initializeCb === 'function') {
              initializeCb();
            }
          } else {
            getNewVersionOfOfflineData();
          }
        });
      } else {
        getNewVersionOfOfflineData();
      }
    });
  }
};

function readDataFromStorage(cb) {
  console.log(`${LOG_PREF}readDataFromStorage`);

  DbPersistence.get(function(err, db) {
    if (err) {
      return; // error already logged by DbPersistence
    }

    if (isDataValid(db) !== true) {
      console.error(`${LOG_PREF}Invalid stored data`);

      DbPersistence.clear(function(err) {
        if (err) {
          // ignored
        }
        initialize(cb, true);
      });
    } else {
      // Data looks valid
      data = db;
      prepareData();

      if (typeof cb === 'function') {
        cb();
      }
    }
  });
}

function prepareData(dataTypes) {
  console.log(`${LOG_PREF}data.version:`, data.version);
  console.log(`${LOG_PREF}EMBARKED_DATA_VERSION:`, process.env.EMBARKED_DATA_VERSION);

  initFieldsIndex(data);

  // Transform data
  const transformTimeKey = 'Transform data';
  console.time(transformTimeKey);

  const transformed = {};
  (dataTypes || VALID_DATA_TYPES).forEach((dataType) => {
    if (!data[dataType]) {
      console.warn(`${LOG_PREF}Missing data: ${dataType}`);
      return;
    }
    transformed[dataType] = transformData(objectToArray(data[dataType].data), dataType);
  });
  console.timeEnd(transformTimeKey);

  // Sort data
  const totalSortTimeKey = `${LOG_PREF}Total sort time`;
  console.time(totalSortTimeKey);

  Object.keys(transformed).forEach((dataType) => {
    appendOrUpdateSortedAndTransformedData(transformed[dataType], dataType);
  });
  console.timeEnd(totalSortTimeKey);
}

export function appendOrUpdateSortedAndTransformedData(data, dataType) {
  sortedAndTransformedData[dataType] = sortItems(data || [], dataType);
}

/**
 * Called by Updater.commitNewVersion
 * @param {function} _cb
 */
export const refresh = (_cb) => {
  console.log(`${LOG_PREF}refresh`);
  const cb = typeof _cb === 'function' ? _cb : function noop() {};

  if (!data) {
    initialize(cb);
  } else {
    if (config.UPDATE_ENABLED !== true) {
      console.log(`${LOG_PREF}Updates are disabled`);
      return;
    }

    const url = DataAssetsUtil.getUrl(
      `manifest/update?version=${data.version}&schema=${
        data.schema
      }&pixel_ratio=1&locale=${get3CharsLang(getCurrentLang())}`,
      true
    );
    fetchHelper(
      url,
      null, // options
      true, // is json
      // on success:
      (json) => {
        const new_datas = json;

        // schema changed
        if (new_datas.schema) {
          DbPersistence.clear(function(err) {
            // `err` ignored
            initialize(cb, true);
            // cb('');
          });
        }
        // update available
        else if (new_datas.version) {
          apply_increment(new_datas, (updated_tables) => {
            initFieldsIndex(data);
            prepareData(updated_tables);
            cb('updated', updated_tables);
          });
        } else {
          cb('noupdate');
        }
      },
      () => {
        cb('noupdate');
        getBindedActions().showNotification({
          message: getLabels().common.fetchError,
          level: NotificationLevels.ERROR,
        });
      }, // on failure
      false // no error message
    );
  }
};

export const getVersion = () => {
  console.log(`${LOG_PREF}getVersion`);
  if (typeof data === 'object' && typeof data.version === 'number') {
    return data.version;
  }
  return 0;
};

export const getSchema = () => {
  console.log(`${LOG_PREF}getSchema`);
  if (typeof data === 'object' && typeof data.schema === 'string') {
    return data.schema;
  }
  return '';
};

function apply_increment(increment, cb) {
  console.log(`${LOG_PREF}apply_increment`);
  const tables = [];

  for (const collection in increment) {
    if (increment.hasOwnProperty(collection) === false) {
      continue;
    }

    if (collection === 'version') {
      continue;
    }

    tables.push(collection);

    for (const i in increment[collection]) {
      if (increment[collection].hasOwnProperty(i) === false) {
        continue;
      }

      if (increment[collection][i] === null || typeof increment[collection][i] === 'undefined') {
        delete data[collection].data[i];
      } else {
        if (
          Array.isArray(data[collection].data) &&
          !data[collection].data.length &&
          typeof increment[collection] === 'object' &&
          !Array.isArray(increment[collection])
        ) {
          data[collection].data = {};
        }
        data[collection].data[increment[collection][i][0]] = increment[collection][i];
      }
    }
  }

  data.version = increment.version;

  DbPersistence.set(data, function(err) {
    if (err) {
      return; // error already logged by DbPersistence
    }
    if (typeof cb === 'function') {
      cb(tables);
    }
  });
}

/**
 * Sort a collection of data
 * @param  {array}  items
 * @param  {string} dataType @see config/dataConfig
 * @param  {number|function} (optional) sortConfig to override default sortConfig for this data type
 * @return {array}
 */
export function sortItems(items, dataType, sortConfig) {
  const _sortConfig = typeof sortConfig !== 'undefined' ? sortConfig : getSortConfig()[dataType];

  // Error case
  if (!_sortConfig) {
    console.warn(`${LOG_PREF}Missing sort configuration for data type: ${dataType}`);
    return items;
  }

  // Sort using value getters
  if (typeof _sortConfig.valueGetter === 'function') {
    switch (_sortConfig.valueType) {
      case 'string':
        items.sort((row1, row2) =>
          sortStrings(_sortConfig.valueGetter(row1), _sortConfig.valueGetter(row2))
        );
        break;

      case 'number':
        items.sort((row1, row2) =>
          simpleSort(_sortConfig.valueGetter(row1), _sortConfig.valueGetter(row2))
        );
        break;

      // Error case
      default:
        console.error(
          `${LOG_PREF}Cannot sort '${dataType}' because of missing or unexpected 'valueType' property: `,
          _sortConfig
        );
    }

    // Sort using a specific function from configuration
  } else if (typeof _sortConfig.sortFunction === 'function') {
    items.sort(_sortConfig.sortFunction);

    // Error case
  } else {
    console.error(
      `${LOG_PREF}Cannot sort '${dataType}' because of unexpected sort configuration: `,
      _sortConfig
    );
    return items;
  }

  if (_sortConfig.reverse) {
    items.reverse();
  }
  return items;
}

/**
 * Sort data to display in data list dialog
 *
 * @param  {array} items
 * @param  {string} dataType
 * @return {array}
 */
export function sortItemsForDataListDialog(items, dataType) {
  const dataListDialogSortConfig = getDataListDialogSortConfig()[dataType];
  if (dataListDialogSortConfig) {
    return sortItems(items, dataType, dataListDialogSortConfig);
  }
  // if no sort configuration is defined, then simply return items
  return items;
}

/**
 * Apply the declared sort config for grouped items,
 *
 * NB: if no 'grouped items sort config' is specified for this data type,
 * then the default sort is applied
 *
 * @param  {array} items
 * @param  {string} dataType
 * @return {array}
 */
export function sortGroupedItems(items, dataType) {
  let sortConf;
  if (typeof getGroupedItemsSortConfig === 'function') {
    const groupedItemsSortConfig = getGroupedItemsSortConfig();
    if (groupedItemsSortConfig) {
      sortConf = groupedItemsSortConfig[dataType];
    }
  }
  return sortItems(items, dataType, sortConf);
}

/**
 * Sort and group events by day for Agenda (ListGroupsPage)
 * @param  {array} events
 * @return {object}
 */
export function sortEventsAndGroupByDay(events) {
  // Group events by day then sort them
  const eventsByDays = {};

  if (!events || events.length === 0) {
    return eventsByDays;
  }

  Object.keys(events).forEach(function(eventId) {
    const event = events[eventId];
    if (event) {
      const date = event.start_date;

      if (typeof eventsByDays[date] === 'undefined') {
        eventsByDays[date] = [];
      }
      eventsByDays[date].push(event);
    }
  });

  Object.keys(eventsByDays).forEach((date) => {
    eventsByDays[date] = sortItems(eventsByDays[date], DATA_TYPE_EVENTS);
  });

  return eventsByDays;
}

/**
 * Transform data from arrays to objects
 * @param  {array} array (of arrays)
 * @param  {string} dataType
 * @return {array} array of objects
 */
function transformData(array, dataType) {
  return array.map((item) => factory(item, dataType));
}

/**
 * Items from json data are arrays.
 * This function uses the `order` attribute (see json) to convert array to object.
 *
 *
 * @param  {array} itemAsArray
 * @param  {string} dataType
 * @return {object}
 */
export function factory(itemAsArray, dataType) {
  if (!itemAsArray) {
    console.error(`${LOG_PREF}Empty item argument`);
    return;
  }
  if (!dataType) {
    console.error(`${LOG_PREF}Empty 'dataType' argument`);
    return;
  }

  const itemFields = getFieldsIndex(dataType);
  if (!itemFields) {
    console.error(`${LOG_PREF}No fields description for data type: ${dataType}`);
    return;
  }

  // Convert
  const object = {};
  Object.keys(itemFields).forEach((field) => {
    object[field] = itemAsArray[itemFields[field]];
  });

  // DEBUG: LOG CATEGORIES WHICH HAVE MIXED CONTENT
  if (process.env.NODE_ENV !== 'production') {
    const childrenDataType = CATEGORIES_MAPPING[dataType];
    if (childrenDataType && object.lump && object.lump.cats && object.lump[childrenDataType]) {
      console.log('HAS CATS AND ITEMS', dataType, object);
    }
  }

  return object;
}

if (config.ENV === 'dev') {
  global.factory = factory;
}

/**
 * e.g input `DATA_TYPE_EVENTS` returns `DATA_TYPE_EVENT_CATEGORIES`
 *     input `DATA_TYPE_EVENT_CATEGORIES` returns `DATA_TYPE_EVENT_CATEGORIES`
 * @param  {string} dataType
 * @return {string}
 */
export function getCategoryDatatype(dataType) {
  let categoryDataType;

  if (dataType) {
    if (CATEGORIES_DATA_TYPE.indexOf(dataType) !== -1) {
      // Current dataType is already a category type
      categoryDataType = dataType;
    } else {
      // Get category dataType from current dataType
      Object.keys(CATEGORIES_MAPPING).forEach((categoryType) => {
        if (CATEGORIES_MAPPING[categoryType] === dataType) {
          categoryDataType = categoryType;
        }
      });
    }
  }
  if (!categoryDataType) {
    // Should not happen
    console.warn(`Cannot determine category data type for: ${dataType}`);
  }
  return categoryDataType;
}

/**
 * Convert app-react dataType to MobiGeo dataType (capitalized singular)
 * @param  {string} dataType
 * @return {string}
 */
export function convertDataTypeToMobigeoType(dataType) {
  if (dataType === DATA_TYPE_FAVORITE_POSITIONS) {
    return window.MobiGeo.Map.POI.types.favorite;
  }
  return dataType.slice(0, 1).toUpperCase() + dataType.slice(1, dataType.length - 1);
}

/**
 * Convert MobiGeo dataType to app-react dataType (lowercased pluralized)
 * @param  {string} dataType
 * @return {string}
 */
export function convertMobigeoType(type) {
  return type.toLowerCase().concat('s');
}
