var defaultConf = require('./config-default');

var BO_URL = 'mom0123-data.ms-dev.mobile-spot.com'; // 'mom202201-data-dev.mobile-spot.com' // to test google maps;

var NODE_BACKEND_DOMAIN = 'mom-node-backend-dev.mobile-spot.com';
var NODE_WS_BAKEND_DOMAIN = '';
var LOCAL_NODE_BACKEND_DOMAIN = 'http://localhost:3082';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: '/home/legacy/safi/mom0123/mom0123-data.ms-dev.mobile-spot.com',
  // '/home/www/safi/mom/mom0122/mom202201-data-dev.mobile-spot.com',
  BO_SERVER_ALIAS: 'ms-dev.mobilespot.prodleni.local',
  BO_SSH_USER: 'legacy',
  BO_SSH_PORT: '22',
  DEPLOY_PATH: '/home/legacy/safi/mom0123/mom0123.ms-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-34',
  projectId: '456727876895',
  // FCM sender id
  appId: '01F69-A9551',
  // pushwoosh appid
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  SYNCHRONIZED_FAVORITES: {
    MODE: 'peering',
    // (peering or login)
    URL: "".concat(NODE_BACKEND_DOMAIN, "/synchro-favorites") //`https://${NODE_BACKEND_DOMAIN}/synchro-favorites`,

  },
  REAL_TIME: {
    URL: "https://".concat(NODE_WS_BAKEND_DOMAIN)
  },
  SSO: {
    SSO_REQUEST_URL: "".concat(NODE_BACKEND_DOMAIN, "/sso-request") //`https://${NODE_BACKEND_DOMAIN}/sso-request`,

  },
  SOCIAL: {
    TWITTER: {
      POST_PER_PAGE: 10
    },
    YOUTUBE: {
      POST_PER_PAGE: 5
    },
    FACEBOOK: {
      POST_PER_PAGE: 5
    },
    FACEBOOK2: {
      POST_PER_PAGE: 5
    },
    INSTAGRAM: {
      POST_PER_PAGE: 5
    },
    URL: "".concat(NODE_BACKEND_DOMAIN, "/social") //`https://${NODE_BACKEND_DOMAIN}/social`,

  },
  SECURED_PDF: {
    SECURED_PDF_REQUEST_URL: "".concat(NODE_BACKEND_DOMAIN, "/secured-pdf-viewer") // `https://${NODE_BACKEND_DOMAIN}/secured-pdf-viewer`, //

  },
  PUBLIC_PDF: {
    PUBLIC_PDF_REQUEST_URL: "".concat(NODE_BACKEND_DOMAIN, "/public-pdf-viewer") // `https://${NODE_BACKEND_DOMAIN}/public-pdf-viewer`

  },
  CONTRIBUTIONS_FEED: {
    URL: "".concat(NODE_BACKEND_DOMAIN, "/contributions-feed") // `https://${NODE_BACKEND_DOMAIN}/contributions-feed`,

  }
});