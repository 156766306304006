import moment from 'moment';
import * as Query from 'src/core/query/Query';
import { DATA_TYPE_HAPPENINGS } from 'app-customs/config/dataConfig';
import { formateDate } from 'src/core/Lang';

export const fetchHappeningsDataFromDB = () => {
  var happenings = Query.getAll(DATA_TYPE_HAPPENINGS);
  if (happenings && happeningAutorized(happenings[0])) {
    return happenings[0];
  }
  return false;
};

export const fetchHappeningsDataLumpFromDB = () => {
  var happenings = Query.getAll(DATA_TYPE_HAPPENINGS);
  if (happenings && happeningAutorized(happenings[0]) && happenings[0].lump && happenings[0].lump.banner) {
    return happenings[0].lump.banner;
  }
  return false;
};

const happeningAutorized = (data) => {
  if (data) {
    let currentDate = new Date(new Date().toDateString()).getTime();
    let dateBeginTime = formateDate(data.start_date, data.start_time);
    let dateEndTime = formateDate(data.end_date, data.end_time);
    return (
      !(dateBeginTime > currentDate || dateEndTime < currentDate) &&
      !(data.lump.interstitiel && data.lump.interstitiel.interOnly)
    );
  }
  return false;
};
